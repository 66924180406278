import { defineStore } from "pinia";

export const selectVlan = defineStore({
  id: "selectVlan",
  state: () => ({
    value: "all",
  }),
  actions: {
    changeSelectVlan(valueVlan) {
      this.value = valueVlan;
      console.log("valueVlan", valueVlan);
    },
  },
  getters: {
    getValueVlan: (state) => {
      return state.value;
    },
  },
});
