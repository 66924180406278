import * as moment from "moment";
import Excel from "exceljs";
import FileSaver from "file-saver";
import mushroom from "cem-probe-api";
import LanguageService from "./LanguageService";
// import { datePicker } from "/src/stores/datePicker";
// const storeDatePicker = datePicker();
let MethodService = {};

// -------------------MethodService-------------------------

MethodService.copyObject = function (value) {
  return JSON.parse(JSON.stringify(value));
};
// bấm sắp xếp bảng
MethodService.tableSortChange = (column, tableRules) => {
  if (column.order === "ascending") {
    tableRules.sort = column.prop;
  } else if (column.order === "descending") {
    tableRules.sort = `-${column.prop}`;
  } else {
    tableRules.sort = "";
  }
};
MethodService.tableSortChangeUC = (column, tableRules) => {
  if (column.prop) {
    tableRules.sort = column.prop;
    column.order === "ascending"
      ? (tableRules.sort_type = 1)
      : (tableRules.sort_type = -1);
  } else {
    tableRules.sort = undefined;
    tableRules.sort_type = undefined;
  }
  return tableRules;
};

// tìm kiếm bảng
MethodService.filterTable = function (jsonFilter) {
  if (jsonFilter) {
    let dataSearch = JSON.parse(jsonFilter);
    let filters = "";
    for (const key in dataSearch.value) {
      const value = dataSearch.value[key];
      if (value) {
        if (dataSearch.operator[key]) {
          let operator = dataSearch.operator[key];
          if (operator == "<+1day") {
            filters +=
              key +
              "<" +
              encodeURIComponent(
                encodeURIComponent(MethodService.addDate(value, "days", 1))
              ) +
              "&";
          } else if (operator == "$vn_text=") {
            filters += operator + encodeURIComponent(value) + "&";
          } else if (operator == ":in_that_day:") {
            filters +=
              key +
              ">=" +
              encodeURIComponent(MethodService.toIsoDate(value[0])) +
              "&" +
              key +
              "<" +
              encodeURIComponent(
                MethodService.toIsoDate(
                  MethodService.addDate(value[1], "days", 1)
                )
              ) +
              "&";
          } else if (operator.match(/^:regex(_\w+)?:$/)) {
            filters += key + operator + encodeURIComponent(value) + "&";
          } else {
            filters += key + operator + encodeURIComponent(value) + "&";
          }
        } else {
          filters += key + "=" + encodeURIComponent(value) + "&";
        }
      }
    }

    return filters.slice(0, -1);
  } else {
    return "";
  }
};

MethodService.stringToObject = function (value) {
  var obj = {};
  if (!value) return obj;

  value.split("&").forEach((frm) => {
    var m = decodeURIComponent(frm).match(
      /^(\w+(\.\w+)*)(=|>=|<=|<>|!=|:\w+:)(.*)/
    );
    if (!m) return;
    if (m[1] in obj) return;
    if (m[3].match(/:regex(_\w+)?:/)) obj[m[1]] = m[4];
    else obj[m[1]] = m[4];
  });

  return obj;
};

// lưu tìm kiếm, sắp xếp khi reload trang web
MethodService.hashUrl = (routeQuery, tableRules) => {
  if (routeQuery.limit) {
    tableRules.limit = parseInt(routeQuery.limit);
  }
  if (routeQuery.page) {
    tableRules.page = parseInt(routeQuery.page);
  }
  if (routeQuery.offset) {
    tableRules.offset = parseInt(routeQuery.offset);
  }
  if (routeQuery.limit && routeQuery.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (routeQuery.sort) {
    tableRules.sort = routeQuery.sort;
    tableRules.defaultSort[0] = tableRules.sort.replace("-", "");
    tableRules.defaultSort[1] =
      tableRules.sort.indexOf("-") != -1 ? "descending" : "ascending";
  }
  if (routeQuery.filters) {
    tableRules.filters = routeQuery.filters;
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  tableRules.showFormSearch =
    routeQuery.showFormSearch == "true" ? true : false;
};

// show lỗi
MethodService.showError = (maCode) => {
  switch (maCode) {
    case 4:
      window.toastr.error(
        LanguageService?.lang.trouble_connecting_to_the_Internet ??
          "trouble_connecting_to_the_Internet"
      );
      break;
    case 2:
      window.toastr.error(
        LanguageService?.lang.lost_connection_to_server ??
          "lost_connection_to_server"
      );
      break;
    default:
      window.toastr.error(
        LanguageService?.lang.having_trouble ?? "having_trouble"
      );
  }
};

function toRad(Value) {
  /** Converts numeric degrees to radians */
  return (Value * Math.PI) / 180;
}

MethodService.distFrom = (lat1, lon1, lat2, lon2) => {
  //Radius of the earth in:  1.609344 miles,  6371 km  | var R = (6371 / 1.609344);
  var R = 3958.7558657440545; // Radius of earth in Miles
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

MethodService.exportExcel = function (cols, dataList, cardTitle) {
  let workbook = new Excel.Workbook();

  workbook.created = new Date();
  workbook.calcProperties.fullCalcOnLoad = false;
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: "visible",
    },
  ];

  let worksheet = workbook.addWorksheet("DataCardItem", {
    views: [{ state: "frozen", xSplit: 1, ySplit: 1 }],
  });

  let arr = [];
  cols?.forEach((item) => {
    let obj = {};
    obj.header = item.title;
    obj.key = item.title;
    obj.width = 30;
    arr.push(obj);
  });

  worksheet.columns = [...arr];

  let row = worksheet.getRow(1);
  row.eachCell(function (cell, colNumber) {
    row.getCell(colNumber).font = { bold: true };
    row.getCell(colNumber).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });

  dataList.forEach((items) => {
    worksheet.addRow(items);
  });

  worksheet.getRow(1).height = 20;
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell(function (cell) {
      if (rowNumber == 1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "151529" },
        };
        cell.font = {
          color: { argb: "FFFFFF" },
          bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      } else {
        cell.alignment = {
          vertical: "middle",
          wrapText: true,
        };
      }
      // Set border of each cell
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
  });

  //thêm ảnh vào file Excel, nhưng đang bị báo lỗi

  // workbook.addImage({
  //   filename: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/JPEG_example_flower.jpg/300px-JPEG_example_flower.jpg',
  //   extension: 'jpg',
  // });

  // workbook.addImage({
  //   filename: 'https://www.example.com/uploads/images/1586877486564_toppicks_thb4.jpg',
  //   extension: 'jpeg',
  // });

  workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, cardTitle + ".xlsx");
  });
};

// format date
MethodService.formatDate = function (value) {
  if (!value) {
    return "";
  }
  if (moment(value).millisecond() === 0) {
    return moment(value).add().format("YYYY-MM-DD HH:mm:ss");
  } else return moment(value).add().format("YYYY-MM-DD HH:mm:ss.SSS");
};
MethodService.formatDateYYYYMMDD = function (value) {
  if (!value) {
    return "";
  }
  return moment(value).add().format("YYYY-MM-DD");
};
MethodService.formatFilterObjToStr = function (value) {
  if (!value) {
    return "";
  }
  return value.join("|");
};
MethodService.downloadFileDashboard = async (id, filename) => {
  const data = { id };
  const headers = {
    "Content-Type": "application/json",
    "X-HTTP-Method-Override": "downloadFile",
    Token: window.localStorage.getItem(
      "mushroom.tokens" + "[" + mushroom.$using() + "]"
    ),
  };
  const response = await fetch(
    mushroom.$using() + "request_download_dashboards/downloadFile",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    }
  );

  const blob = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = response.headers.get("filename") || filename;
  await link.click();
};

MethodService.addDays = (dateObj, numDays) => {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
};
MethodService.subtractDays = (dateObj, numDays) => {
  dateObj.setDate(dateObj.getDate() - numDays);
  return dateObj;
};

MethodService.fnRenderReAuthen = (row, index) => {
  if (row[index]?.length == 0) return "";
  let stringHTML = "";
  let jsonData = JSON.parse(row[index]);
  for (let key in jsonData) {
    const text = `<a href="${
      location.origin +
      "/customers/session?service_id=" +
      jsonData[key].service_id +
      "&start_date=" +
      row[4] +
      "&stop_date=" +
      row[4] +
      "&mac=" +
      row[2]
    }" style="color: inherit">${
      jsonData[key].display_name + ": " + jsonData[key].value
    }</a>`;
    stringHTML = stringHTML + text + "</br>";
  }
  return stringHTML;
};

MethodService.fnRenderSpeedTest = (row, index) => {
  if (row[index]?.length == 0) return "";
  let stringHTML = "";
  let jsonData = JSON.parse(row[index]);
  for (let key in jsonData) {
    const text = `<a href="${
      location.origin +
      "/customers/session?service_id=" +
      jsonData[key].service_id +
      "&start_date=" +
      row[4] +
      "&stop_date=" +
      row[4] +
      "&mac=" +
      row[2]
    }" style="color: inherit">${
      jsonData[key].display_name + ": " + jsonData[key].value
    }</a>`;
    stringHTML = stringHTML + text + "</br>";
  }
  return stringHTML;
};

MethodService.fnRenderBehavior = (data) => {
  let stringHTML = "";
  if (data.behavior) {
    let jsonData = JSON.parse(data.behavior);

    for (const key in jsonData) {
      let text = "";
      jsonData[key].is_click?.toLowerCase() === "true"
        ? (text = `<a href="${
            location.origin +
            "/customers/session?service_id=" +
            jsonData[key].service_id +
            "&start_date=" +
            data.date +
            "&stop_date=" +
            data.date +
            "&mac=" +
            data.mac_cust
          }" data-toggle="tooltip" data-placement="top" title="${
            jsonData[key].tooltip
          }"
       >${jsonData[key].display_name + ": " + jsonData[key].value}</a>`)
        : (text = `<span style="color: #e74c3c" data-toggle="tooltip" data-placement="top" title="${
            jsonData[key].tooltip
          }">${
            jsonData[key].display_name + ": " + jsonData[key].value
          }</span>`);
      stringHTML = stringHTML + text + "</br>";
    }
  } else stringHTML = "<span class='none-dataUC'>-</span>";
  return stringHTML;
};
MethodService.fnRenderReAuthenCol = (data) => {
  if (!data) return;
  let jsonData = JSON.parse(data);
  let stringHTML = "";
  for (const key in jsonData) {
    const text = `<a href="#"
     >${jsonData[key].display_name + ": " + jsonData[key].value}</a>`;
    stringHTML = stringHTML + text + "</br>";
  }
  return stringHTML;
};
MethodService.fnRenderServiceCol = (data) => {
  let stringHTML = "";
  if (data.service_alert) {
    let jsonData = JSON.parse(data.service_alert);
    for (const key in jsonData) {
      let text = "";
      jsonData[key].is_click?.toLowerCase() === "true"
        ? (text = `<a href="${
            location.origin +
            "/customers/session?service_id=" +
            jsonData[key].service_id +
            "&start_date=" +
            data.date +
            "&stop_date=" +
            data.date +
            "&mac=" +
            data.mac_cust
          }" data-toggle="tooltip" data-placement="top" title="${
            jsonData[key].tooltip
          }"
       >${jsonData[key].display_name + ": " + jsonData[key].value + " "}</a>`)
        : (text = `<span style="color: #151529" data-toggle="tooltip" data-placement="top" title="${
            jsonData[key].tooltip
          }">${
            jsonData[key].display_name + ": " + jsonData[key].value
          }</span>`);
      stringHTML = stringHTML + text + "</br>";
    }
  } else stringHTML = "<span class='none-dataUC'>-</span>";
  return stringHTML;
};
MethodService.fnRenderMAC = (data) => {
  if (!data) return;
  let stringHTML = "";
  const text = `<a href="${
    location.origin +
    "/customers/dashboard-timespan" +
    "?start_date=" +
    data.date +
    "&stop_date=" +
    data.date +
    "&mac=" +
    data.mac_cust
  }"
  style="color: #606266" >${data.mac_cust}</a>`;

  stringHTML = stringHTML + text;
  return stringHTML;
};

MethodService.fnRenderService = (row, index) => {
  if (row[index]?.length == 0) return "";
  let jsonData = JSON.parse(row[index]);
  let stringHTML = "";
  for (const key in jsonData) {
    const text = `<a href="${
      location.origin +
      "/customers/session?service_id=" +
      jsonData[key].service_id +
      "&start_date=" +
      row[4] +
      "&stop_date=" +
      row[4] +
      "&mac=" +
      row[2]
    }" style="color: inherit">${
      jsonData[key].display_name + ": " + jsonData[key].value
    }</a>`;
    stringHTML = stringHTML + text + "</br>";
  }
  return stringHTML;
};

MethodService.fnRenderUC53 = (row, index) => {
  let stringHTML = `<a href="${
    location.origin +
    "/usecases/usecase-53" +
    "?start_date=" +
    row[4] +
    "&stop_date=" +
    row[4] +
    "&mac=" +
    row[2]
  }" style="color: inherit">${row[index]}</a>`;
  return stringHTML;
};

MethodService.fnRenderUsedHour = (row, index) => {
  let stringHTML = `<a href="${
    location.origin +
    "/usecases/uc531" +
    "?start_date=" +
    row[4] +
    "&stop_date=" +
    row[4] +
    "&mac=" +
    row[2] +
    "&service_name=" +
    row[5] +
    "&table_id=" +
    row[10]
  }" style="color: inherit">${row[index]}</a>`;
  return stringHTML;
};

MethodService.fnRenderServerIP = (data, idUC) => {
  if (!data) return;
  let stringHTML = "";
  if (data.ip_server_str.length < 16) {
    stringHTML = `<a href="${
      location.origin +
      "/usecases/uc621/" +
      idUC +
      "?start_date=" +
      data.date +
      "&stop_date=" +
      data.date +
      "&ip=" +
      data.ip_server_str
    }">${data.ip_server_str}</a>`;
  } else {
    stringHTML = `<a href="${
      location.origin +
      "/usecases/uc621/" +
      idUC +
      "?start_date=" +
      data.date +
      "&stop_date=" +
      data.date +
      "&ip=" +
      data.ip_server_str
    }" data-toggle="tooltip" data-placement="top" title="${
      data.ip_server_str
    }">${data.ip_server_str}</a>`;
  }
  return stringHTML;
};

MethodService.fnRenderDomain = (data) => {
  if (!data) return;
  let stringHTML = "";
  // if (data.domain.length < 35) {
  //   stringHTML = `<span class="none-dataUC">${data.domain}</span>`;
  // } else {
  //   stringHTML = `<span class="none-dataUC" data-toggle="tooltip" data-placement="top" title="${data.domain}">${data.domain}</span>`;
  // }
  stringHTML = `<span class="none-dataUC" data-toggle="tooltip" data-placement="top">${data.domain}</span>`;
  return stringHTML;
};

MethodService.fnRenderISP = (data) => {
  if (!data) return;
  let stringHTML = `<span class="none-dataUC" data-toggle="tooltip" data-placement="top" title="${data.isp}">${data.isp}</span>`;
  return stringHTML;
};
MethodService.fnRenderCategoryUC62 = (data) => {
  if (!data) return;
  let stringHTML = `<span class="none-dataUC" data-toggle="tooltip" data-placement="top" title="${data.category}">${data.category}</span>`;
  return stringHTML;
};

MethodService.isNoData = (data) =>
  data === undefined ||
  data == null ||
  data === "" ||
  data === "NA" ||
  data === "-";

MethodService.htmlEntities = (str) => {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
};

MethodService.fnRenderFailCustNumber = (data, idUC) => {
  let stringHTML = `<a href="${
    location.origin +
    "/usecases/uc622/" +
    idUC +
    "?start_date=" +
    data.date +
    "&stop_date=" +
    data.date +
    "&ip=" +
    data.ip_server_str
  }" style="color: inherit">${data.fail_cust_number}</a>`;
  return stringHTML;
};
MethodService.fixedNumber = (number) => {
  if (isNaN(number) || number === "" || number === null) return "NA";

  return new Intl.NumberFormat("en-US").format(Number(number).toFixed(2));
};
MethodService.getColorByRange = (number, dataValue) => {
  let colorValue = "";
  if (!number) return;
  if (number.toString().match(/[a-zA-Z]/)) return;
  if (!number.toString().match(/[a-zA-Z]/)) {
    if (!dataValue) return;
    number = Number(number);
    for (let i = 0; i < dataValue.length; i++) {
      if (!dataValue[i].min && !dataValue[i].max)
        colorValue = dataValue[i].color;
      else if (dataValue[i].min && !dataValue[i].max) {
        colorValue = dataValue[i].color;
      }
      if (number < dataValue[i].max && number >= dataValue[i].min) {
        colorValue = dataValue[i].color;
        break;
      }
    }
  }
  return colorValue;
};
MethodService.thumbIcon = (idImage, height) => {
  return mushroom.$file.linkBuilder.thumb
    .id(idImage)
    .clip(height, height, "middle", "center")
    .build();
};
MethodService.renderLinkChart = (x, y, value, link) => {
  if (!link) return;
  let strLink = link;
  if (!x && !y && !value) return link;
  if (x) {
    if (link.includes("?")) {
      link.slice(-1) === "?" ? (strLink += `x=${x}`) : (strLink += `&x=${x}`);
    } else strLink += `?x=${x}`;
    if (y) strLink += `&y=${y}`;
  } else {
    if (y) {
      if (link.includes("?")) {
        link.slice(-1) === "?" ? (strLink += `y=${y}`) : (strLink += `&y=${y}`);
      } else strLink += `?y=${y}`;
    }
  }
  if (value) strLink += `&value=${value}`;
  return strLink;
};

MethodService.checkFormatDateYYYYMMDD = (date) => {
  return moment(date, "YYYY-MM-DD", true).isValid();
};
MethodService.checkFormatDateTime = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss", true).isValid();
};
MethodService.createKey = (data) => {
  const str = Math.random().toString(36).substring(2, 7);
  if (data?.some((item) => item.key === str)) MethodService.createKey(data);
  else return str;
};
MethodService.removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    // eslint-disable-next-line no-useless-escape
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};
MethodService.trimSpace = (str) => {
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
};
MethodService.routerReplace = (nameRouter, tableRules) => {
  let objRouter = {};
  objRouter.name = nameRouter;
  objRouter.query = {
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    filters: tableRules.filters,
    showFormSearch: tableRules.showFormSearch,
  };
  return objRouter;
};
MethodService.isDashboardPopup = async (idCard) => {
  try {
    const response = await mushroom.card.findByIdAsync({
      id: idCard,
      fields: "dashboard",
    });
    if (response.result?.dashboard === "popup") return true;
    return false;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
export default MethodService;
