import { defineStore } from 'pinia'


export const abortAPI = defineStore({
    id: 'abortAPI',
    state: () => ({
        controllers: [],
        abort: null,
    }),
    actions: {
        // addAbortAPI(data, key) {
        //     this.controllers.push({
        //         data: data,
        //         key: key
        //     });
        //     console.log('data thêm vào danh sách abort', data);
        //     console.log('danh sách abort', this.controllers);
        // },
        // deleteAbortAPI(key) {
        //     // xóa controller của api đã gọi xong
        //     this.controllers = this.controllers.filter(item => item.key !== key);
        // },
        // abortController() {
        //     console.log('xóa abort')
        //     this.controllers.forEach(item => item.abort());
        //     this.controllers = [];
        // },
        setAbort(data) {
            this.controllers.push(data);
        },
        deleteAbort() {
            if(this.controllers.length > 0) this.controllers.forEach(item => item.abort());
            this.controllers = [];
        }
    },
    getters: {
        showEdit: () => {
            return this.controllers;
        }
    }
})