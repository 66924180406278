import vi from "../lang/vi.json";
import en from "../lang/en.json";
// thêm các ngôn ngữ khác ở dưới
let lang;
let LanguageService = {
    VI: vi,
    EN: en,
};

if (localStorage.language) {
    lang = localStorage.language.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang];
} else if (localStorage.language_default) {
    lang = localStorage.language_default.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang];
} 
LanguageService.lang ??= vi;

export default LanguageService;