import { defineStore } from 'pinia'


export const changeEdit = defineStore({
    id: 'changeEdit',
    state: () => ({
        edit: false,
    }),
    actions: {
        changeSettingEdit() {
            this.edit = !this.edit
        },
    },
    getters: {
        showEdit: (state) => {
            if (state.edit == true) return true
            return false
        }
    }
})